import "../App.css";
import icon from "../ic_launcher.png";

export function Privacy() {
    return (
      <div className="App-header" style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "lightslategray",
        alignItems: "center",
        color: "white"
     }}>
        <header className="Page-title">
          <div>pomoFLOW Privacy Policy</div>
        </header>
        <img src={icon} alt="Icon" style={{ margin: "20px", filter: "drop-shadow(6px 6px 2px #000000)" }}/>
        <p className="Product-long-description">      
          <p>
            Personal Information
            <ul>
              <li>We do not collect any personal information through the application</li>
              <li>We do not share your data with anyone else</li>
            </ul>
            Diagnostic and Usage Data
            <ul>
              <li>We do not collect or receive any diagnostic or usage information beyond what is provided by Apple and Google
                as part of the participation in their Developer programs. This diagnostic and usage data includes, but is not 
                limited to, the operating system version, device type, app version, and any applicable crash logs.</li>
            </ul>
            Third Parties
            <ul>
              <li><a href="https://www.apple.com/privacy/" style={{color: 'white'}}>Apple</a></li>
              <li><a href="https://policies.google.com/privacy" style={{color: 'white'}}>Google Play Services</a></li>
            </ul>
            Changes to this Privacy Policy
            <ul>
              <li>This privacy policy may be updated from time to time. You are therefore advised to review this page
                periodically for any changes. Any such changes are effective immediately after they are posted to this page. 
                This policy was last updated on December 13th 2022.</li>
            </ul>
          </p>
        </p>
      </div>
    );
  }
  