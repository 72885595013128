import { Typography } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import google from "./googleplay153x46.png";
import fire from "./fire153x46.png";
import msstore from "./MS_864X312.svg";

export function Home() {
   return (
      <div
         className="App"
         style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "lightslategray", //"#FE4042",
            color: "white"
         }}
      >
         <div>
            <Typography variant="h1" component="h2">
               pomoFLOW
            </Typography>
            <Typography component="h2" style={{ margin: "40px" }}>
               pomoFlow is an a simple and basic, pomodoro application. You will not find task management features etc. as this was
               designed specifically to be used along side a user's existing task management tool. What you get is essentially a pomodoro timer, 
               with the standard four pomodoro's with short breaks between the first three and a long break after the fourth. The break times and 
               pomodoro time's are set, be default to a relatively standard values, but you can change to suit.
            </Typography>
            <Typography component="h2" style={{ margin: "10px" }}>
               Available via
            <p>
               <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
               <a href='https://play.google.com/store/apps/details?id=com.putridparrot.pomoflow'><img alt='Get it on Google Play' src={google}/></a>
               &nbsp;&nbsp;
               <a href='https://www.amazon.co.uk/MTBSOFTWARE-LIMITED-pomoFLOW/dp/B0C3ZWRSK1/'><img alt='Download on the Amazon Store' src={fire}/></a>
               &nbsp;&nbsp;
               <a href='ms-windows-store://pdp/?productid=9MZK8B6WBZ8Z'><img alt='Download on the Microsoft Store' src={msstore}/></a>
               </div> 
            </p>
            </Typography>
            <Typography variant="h6" component="h2">
               <a href="./Privacy" style={{
                  color: "white"
               }}>privacy policy</a>
            </Typography>
         </div>
      </div>
   );
}
