import './App.css';
import { Routes, Route } from "react-router-dom";
import { Home } from './controls/Home';
import { Privacy } from './controls/Privacy';

function App() {

  return (
    <Routes>
      <Route path="/privacy" element={<Privacy />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default App;
